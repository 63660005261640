.error {
  color: var(--wt-color-error)
}

.header {
  svg {
    color: #27282C;
    opacity: 70%;
  }
}
