$empty-number-cell-color: var(--wt-color-dark-20);
$full-number-cell-color: var(--wt-color-error);

.licensesInfoLinkEmbedded {
  font-size: 16px;
  line-height: 20px;
}

.licensesInfoLink {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.numberCell {
  text-align: right;
}

.emptyNumberCell {
  color: $empty-number-cell-color;
}

.fullNumberCell {
  color: $full-number-cell-color;
}

.versionsCell {
  padding-top: 12px;

  &:last-child {
    padding-bottom: 8px;
  }
}

.chart {
  position: relative;
  height: 30px;

  & > div {
    position: absolute;
    left: -38px
  }
}

.chartCell {
  width: 350px;
}

.error {
  color: var(--wt-color-error);
}

.h3 {
  font-size: 16px;
  line-height: 20px;
}

.table tbody > tr > td {
  padding-top: 6px;
  padding-bottom: 11px;
}
